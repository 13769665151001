import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [
      CommonModule,
      MatIconModule,
      MatButtonModule
    ]
})
export class StarRatingComponent implements OnInit {
  @Input() rating = 0;
  @Input() starCount = 5;
  @Input() color = 'accent';
  @Input() showCounter = true;
  @Input() disabledStar = true;
  @Input() iconButtonSize = '';
  @Output() ratingUpdated = new EventEmitter();

  ratingArr = [];

  constructor() {}

  ngOnInit() {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }
  onClick(rating: number) {
    this.ratingUpdated.emit(rating);
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'material-symbols-inlined';
    } else {
      return '';
    }
  }
}

